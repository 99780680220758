
import { Component, Vue } from "vue-property-decorator";
import { CustomerContract } from "@/config/Modules";

@Component({
  components: {},
})
export default class CustomerContracts extends Vue {
  protected redirectRouteNameOnDeleteSuccess = "CustomerDashboard";

  protected descriptionField = CustomerContract.description_field;
}
